//import logo from './logo.svg';

import '../styles/Mobile.css';
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Component } from 'react'
import moment from 'moment'

// the summoner screen is the main view of the app

// there will be a nice bar at the top with the summoner name and maybe a tagline to go with i


// TODO:
// - Add a slider in the recent stats panel to make
//  it possible to select a range of games from a summoner's history.
// - Add badges on the right hand side
// - Add further detail to each match preview (accordion, graphing, etc)
// - Add role and playstyle insights on the right side as initially planned
// - Add placeholder for when the games are loading!


const PROXY_alt = "/";

const PROXY = "https://data.ellaaa.ca/"

function stub (patch) {
    return "https://ellaaa.ca/datadragon/" + patch + "/";
}

function stub_ext (patch){
    return "https://ellaaa.ca/datadragon/" + patch + "/" + patch + "/";
}

function blank (patch) {
    return "https://ellaaa.ca/datadragon/" + patch + "/0.png";
}

// fetch path of assets from API
function Kda(object) {

    let kills = object.kills;
    let deaths = object.deaths;
    let assists = object.assists;

    return (kills + assists) / deaths;

}

function AverageOutStatline(object) {

    let kills = object.kills;
    let deaths = object.deaths;
    let assists = object.assists;

    let games = object.wins + object.losses;

    return {
        kills: (kills / games),
        deaths: (deaths / games),
        assists: (assists / games)
    }
}

function Winrate(object) {
    let wins = object.wins;
    let losses = object.losses;
    return ((wins / (wins + losses)) * 100).toFixed(0);
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function calculateKP(matchdata) {
    // calculates KP of the summoner focused on by matchdata
    // add up all team's kills
    // divide by player's kills + assist

    let pos = matchdata['position'];

    let summ_data = matchdata['teams'][pos['team']][pos['index']];

    let total_kills = 0;

    for (let x of matchdata['teams'][pos['team']]) {
        // this iterates over the summoner's team
        total_kills += x['kills'];
    }

    let partial = (summ_data['kills'] + summ_data['assists']);
    let calculated = (partial / total_kills) * 100;
    return calculated.toFixed(0);

}



// Top level wrapper to pass the parameters from URL pattern -> state based component
const New = () => {
    let params = useParams();
    return <StateSummoner params={params} />
}



class StateSummoner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            summoner: this.props.params['name'],
            region: this.props.params['region'],
            data: {
                mastery: [],
                summoner_level: 0,
            },
            matches: [

            ],
            stats: {},
            summoner_search: "",
            region_search: "NA",
            matches_loaded: 0,
            patch: "12.1.1",
        };

        this.updateData = this.updateData.bind(this);
        this.handleSummoner = this.handleSummoner.bind(this);
        this.handleRegion = this.handleRegion.bind(this);
        this.computeRecentStats = this.computeRecentStats.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }


    // callbacks for updating search bar
    handleSummoner(event) {
        this.setState(
            {
                summoner_search: event.target.value
            }
        );
    }
    handleRegion(event) {
        this.setState(
            {
                region_search: event.target.value
            }
        );
    }


    loadMore() {

        axios.get(PROXY + "api/matches/" + this.state.region + "/" + this.state.summoner + "/" + this.state.matches_loaded + "/10")
            .then(
                (res) => {
                    // take the newly loaded matches and append to the current list
                    this.setState((state, props) => {

                        return {
                            matches: [...state.matches, ...res.data],
                            matches_loaded: state.matches_loaded + res.data.length
                        }

                    }
                        // get 10 more matches, append them to the list of matches
                    )
                    this.computeRecentStats()
                }
            ).catch((err) => console.error(err));
    }

    updateData() {

        this.setState(
            {
                matches: [],
            }
        );
        // update the summoner data
        axios.get(PROXY + "api/update/summoner/" + this.state.summoner)
            .then((res) => {
                //console.log("Updating!")
                //console.log(res.data)
                axios
                    .get(PROXY + "api/matches/update/" + this.state.region + "/" + this.state.summoner + "/0/10")
                    .then((res1) => {
                        console.log(res1.data.length);
                        this.setState({
                            matches: res1.data,
                            data: res.data,
                            matches_loaded: res1.data.length
                        })

                        this.computeRecentStats();

                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => {
                console.error(err);
            });

    }

    computeRecentStats() {
        // this computes the stats that appear in the right panel of the main summoner window
        //console.log("Computing stats...");
        // compute match winrate
        let matches = this.state.matches; // the list of all matches being displayed

        //console.log(matches);

        if (!(matches === undefined)) {

            // compute average KDA (calculate average kills, deaths, assists separately)

            let data = {
                // winrate data
                wins: 0,
                losses: 0,

                // statline data
                kills: 0,
                deaths: 0,
                assists: 0,

                champions: {}

            }


            for (let matchdata of matches) {// iterate over all of the matches
                if (!(matchdata === undefined)) {
                    // find the summoner from the position
                    let pos = matchdata['position']; // position data indicating current summoner
                    if (!(pos === undefined)) {

                        let summ_data = matchdata['teams'][pos['team']][pos['index']]; // actual matchdata for summoner

                        let win = (summ_data['win']); // true or false

                        (win) ? data.wins++ : data.losses++; // increment wins / losses

                        data.kills += summ_data['kills'];
                        data.deaths += summ_data['deaths'];
                        data.assists += summ_data['assists'];


                        let champ_data = data.champions[summ_data['champion']['id']];

                        if (champ_data === undefined) {
                            // add data to object
                            data.champions[summ_data['champion']['id']] = {
                                kills: summ_data['kills'],
                                deaths: summ_data['deaths'],
                                assists: summ_data['assists'],
                                wins: (win) ? 1 : 0,
                                losses: (win) ? 0 : 1,
                                name: summ_data['champion']['name'],
                                id: summ_data['champion']['id'],
                            }
                        } else {
                            // increment data
                            champ_data.kills += summ_data['kills'];
                            champ_data.deaths += summ_data['deaths'];
                            champ_data.assists += summ_data['assists'];
                            (win) ? champ_data.wins++ : champ_data.losses++; // increment wins / losses

                        }

                    }

                }


            }

            this.setState({ stats: data }); // updates the state summary data

        }



    }

    componentDidMount() {
        //console.log(this.state.region + ' ' + this.state.summoner);
        // get summoner data

        axios.get(PROXY + "api/dd/version").then((res) => {
            console.log("DATADRAGON VERSION: " + res.data);
            this.setState({patch: res.data});
            // Now this needs to be passed to just about every subcomponent so that it can render up to date datadragon
        })

        axios
            .get(PROXY + "api/summoner/" + this.state.region + "/" + this.state.summoner)
            .then((res) => {

                axios
                    .get(PROXY + "api/matches/" + this.state.region + "/" + this.state.summoner + "/0/10")
                    .then((res1) => {

                        this.setState({
                            matches: res1.data,
                            data: res.data,
                            matches_loaded: res1.data.length
                        })

                        this.computeRecentStats();

                    })
                    .catch((err) => console.error(err));


            })
            .catch((err) => console.error(err));


    }

    render() {
        return (
            <Summoner patch={this.state.patch} name={this.state.summoner} data={this.state.data} matches={this.state.matches} update={this.updateData} stats={this.state.stats} handlers={[this.handleSummoner, this.handleRegion, this.loadMore]} region_search={this.state.region_search} summoner_search={this.state.summoner_search} />
        );
    }

}




function Summoner(props) {
    let filtered = props.matches.filter(value => Object.keys(value).length !== 0);
    return (
        <div>
            <nav className="navbar navbar-light fixed-top bg-white">
                <div className="container-fluid">
                    <a className="brand navbar-brand" href="/">Ella</a>
                    <div className="d-flex">
                        <button className="btn btn-primary me-2" onClick={props.update}>Update</button>
                        <input onChange={props.handlers[0]} className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <select onChange={props.handlers[1]} className="form-select me-2">
                            <option value="NA">NA</option>
                            <option value="EUNE">EUNE</option>
                            <option value="EUW">EUW</option>
                            <option value="KR">KR</option>
                            <option value="BR">BR</option>
                            <option value="LAN">LAN</option>
                            <option value="LAS">LAS</option>
                            <option value="JP">JP</option>
                            <option value="TR">TR</option>
                            <option value="RU">RU</option>
                            <option value="OCE">OCE</option>
                        </select>
                        <a href={"/summoner/" + props.region_search + "/" + props.summoner_search}><button className="btn btn-outline-dark" type="submit">Search</button></a>
                    </div>
                </div>
            </nav>

            <div className="ss-main container-fluid">
                <div className="ss-summoner ">
                    <LeftComponent patch={props.patch} name={props.name} level={props.data.summoner_level} ranked={props.data.ranked} mastery={props.data.mastery} />
                </div>

                <div className="ss-content">


                    <div className="ss-recent-stats container-xs">
                        {Object.keys(props.stats).length === 0 && <RightComponentPlaceholder patch={props.patch} />}
                        {Object.keys(props.stats).length !== 0 && <RightComponent patch={props.patch} stats={props.stats} />}
                    </div>


                    <div className="ss-recent-games container-xl">

                        <RecentGames patch={props.patch} matches={filtered} handler={props.handlers[2]} />


                        <p className="tiny-text legal-alt my-3"><small>Ella isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.</small></p>
                        <p className="legal-alt">Developed by Dylan Leclair © 2021</p>

                    </div>




                </div>

            </div>


        </div>
    );
}



function RecentGames(props) {

    let matches = [];

    if (Array.isArray(props.matches)) {
        matches = props.matches.map((x, index) => {
            return <div key={index}><Game patch={props.patch} matchdata={x} /><hr /></div>
        });
    }

    return (
        <div className="game-container">
            {matches.length > 0 && matches}
            <button className="btn btn-lg btn-block btn-outline-dark show-more" onClick={props.handler}>Show more...</button>
        </div>
    );
}

function RightComponent(props) {


    // check if the stats are calculated
    // console.log(props.stats)

    let winp = ((props.stats.wins / (props.stats.wins + props.stats.losses)) * 100).toFixed(0);
    let lossp = (100 - winp).toFixed(0);

    let kda = (props.stats.kills + props.stats.assists) / props.stats.deaths; // kda
    kda = kda.toFixed(2);

    let games = props.stats.wins + props.stats.losses;

    let avg_kills = (props.stats.kills / games).toFixed(1);
    let avg_deaths = (props.stats.deaths / games).toFixed(1);
    let avg_assists = (props.stats.assists / games).toFixed(1);


    // check if the stats are calculated
    // console.log(props.stats)

    let champs = []

    let keys = Object.keys(props.stats.champions);
    if (keys.length < 5) {

        keys.map((x) => {
            champs.push(props.stats.champions[x]);
            return x;
        });

    } else {
        // there are at least 5 champions
        // pick by: 
        //  - most games played
        //  - highest kda per games played

        let data = {};

        for (let x of keys) {
            let y = props.stats.champions[x];
            let games = y.wins + y.losses;
            if (data[games] === undefined) {
                data[games] = [y];
            } else {
                data[games].push(y);
            }
        }

        // sort data by kda
        for (let x of Object.keys(data)) {
            let d = data[x];
            d.sort((a, b) => Kda(b) - Kda(a));
        }

        while (champs.length < 5) {
            //champs.push(props.stats.champions[keys.shift()]);
            // find highest key in data
            let g = Object.keys(data);
            g.sort((a, b) => b - a); // sort in descending

            champs.push(data[g[0]].shift());

            if (data[g[0]].length === 0) {
                delete data[g[0]];
            }
            // add highest KDA champ

        }


    }


    // map the data in champs to be rendered

    let champs_rendered = champs.map((x) => {
        return <RecentChampComponent patch={props.patch} stats={x} key={x.id} />
    })


    return (
        <div className="ss-right-container container-xs my-2">
            <div className="ss-right-container-box flex-col w-100">
                <p className="ss-subtitle lead no-margin">Recent Stats</p>
                <p className="lead small-margin"><small><strong>{winp}%</strong> W/R - <strong>{kda}</strong> KDA</small></p>

                <div id="bar" className="progress small-margin">
                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: winp + "%" }} aria-valuenow={80} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: lossp + "%" }} aria-valuenow={80} aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div className="small-margin">{avg_kills}/<span className="text-danger">{avg_deaths}</span>/{avg_assists} | <small>{games} games</small></div>

                <p className="ss-recent-champs subtitle lead small-margin">Recent Champions</p>

                <div className="ss-recent-champs flex-col justify-content-center align-items-start w-100">

                    {champs && champs_rendered}

                </div>
                <p className="tiny-text legal"><small>Ella isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.</small></p>
                <p className="legal">Developed by Dylan Leclair © 2021</p>
            </div>

        </div>
    );
}



function RightComponentPlaceholder(props) {


    return (
        <div className="ss-right-container container-xs my-2">
            <div className="ss-right-container-box flex-col w-100">
                <p className="subtitle lead no-margin">Recent Stats</p>
                <p className="lead small-margin"><small>Loading your stats...</small></p>

                <div id="bar" className="progress small-margin">
                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: "80%" }} aria-valuenow={80} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: "80%" }} aria-valuenow={80} aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div className="small-margin"><small>Loading statline...</small></div>

                <p className="ss-recent-champs subtitle lead small-margin">Recent Champions</p>

                <div className="ss-recent-champs flex-col justify-content-center align-items-start w-100">
                    <ChampionStatsPlaceholder patch={props.patch}/>
                    <ChampionStatsPlaceholder patch={props.patch}/>
                    <ChampionStatsPlaceholder patch={props.patch}/>
                    <ChampionStatsPlaceholder patch={props.patch}/>
                    <ChampionStatsPlaceholder patch={props.patch}/>
                </div>
                <p className="tiny-text legal"><small>Ella isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.</small></p>
                <p className="legal">Developed by Dylan Leclair © 2021</p>
            </div>

        </div>
    );
}




function ChampionStatsPlaceholder(props) {
    return (
        <div className="d-flex my-2 w-100">
            <img className="summ-champ" src={blank(props.patch)} alt="champion_tile"></img>
            <div className="d-flex justify-content-between align-items-center w-70 indent-s">
                <div className="flex-col w-100">
                    <div id="bar" className="progress small-margin">
                        <div className="progress-bar bg-secondary" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div id="bar" className="progress small-margin">
                        <div className="progress-bar bg-medium" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function Game(props) {
    let matchdata = props.matchdata;
    let metadata = matchdata['metadata'];
    let pos = matchdata['position'];

    let summ_data = matchdata['teams'][pos['team']][pos['index']];

    let win = (summ_data['win']) ? "Win" : "Loss";
    let win_color = (summ_data['win']) ? "bg-primary" : "bg-danger";
    let gamemode = metadata['gameMode'];

    let gamestart = metadata['gameStart'] / 1000;

    let duration = metadata['gameDuration'] / 1000;
    let fromNow = moment.unix((gamestart + duration)).fromNow();

    duration = new Date(metadata['gameDuration'] * 1000);



    let hours = duration.getHours();
    let mins = duration.getMinutes();
    let seconds = duration.getSeconds();

    let h = (hours > 0) ? "" : (hours + "h ");

    let time = h + mins + "m " + seconds + "s";

    let kills = summ_data['kills'];
    let deaths = summ_data['deaths'];
    let assists = summ_data['assists'];

    let kda = ((kills + assists) / deaths).toFixed(2);

    let level = summ_data['champion_level'];
    let cs = summ_data['minion_kills'];
    //let kp = ; // summ up all the kills and divide kills + assists

    let team0 = [];
    props.matchdata['teams'][0].map((x, index) => {
        team0.push(<Player patch={props.patch} champ={x['champion']['id']} name={x['summoner_name']} key={index} />);
        return x;
    });

    let team1 = [];
    props.matchdata['teams'][1].map((x, index) => {
        team1.push(<Player patch={props.patch} champ={x['champion']['id']} name={x['summoner_name']} key={index} />);
        return x;
    });

    let items = summ_data['items'];

    let kp = calculateKP(matchdata);

    let summs = [summ_data['summoner1']['image']['full'], summ_data['summoner2']['image']['full']]

    let champ = summ_data['champion']['id']


    return (
        <div className="ss-game container-xl">
            <div className="ss-game-tagline">
                <p className="lead"><span className={"badge " + win_color + " px-3"}>{win}</span> {gamemode} - {time} - {fromNow}</p>
            </div>
            <div className="ss-game-champ">
                <img className="champ-slice" src={stub(props.patch) + "img/champion/loading/" + champ + "_0.jpg"} alt="champ-preview" />
            </div>


            <div className="ss-game-stats">
                <p className="title small-margin">{kills}/<span className="text-danger">{deaths}</span>/{assists}</p>
                <p className="small-margin"><strong>{kda}</strong> KDA <span className="badge bg-danger"></span></p>
                <p className="small-margin"><small>Level {level} - {cs} CS - KP {kp}%</small></p>
            </div>

            <div className="ss-game-build">

                <div className="ss-build-summs ss-build-table">
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "img/spell/" + summs[0]} alt="summoner-spell" />
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "/img/spell/" + summs[1]} alt="summoner-spell" />
                </div>

                <div className="ss-build-items ss-build-table">
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "img/item/" + items[0] + ".png"} alt="item" />
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "img/item/" + items[1] + ".png"} alt="item" />
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "img/item/" + items[2] + ".png"} alt="item" />
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "img/item/" + items[3] + ".png"} alt="item" />
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "img/item/" + items[4] + ".png"} alt="item" />
                    <img className="ss-item rounded" src={stub_ext(props.patch) + "img/item/" + items[5] + ".png"} alt="item" />
                </div>


                <div className="ss-build-vision">
                    <img className="ward" src={stub_ext(props.patch) + "img/item/" + items[6] + ".png"} alt="ward-icon" />
                    <p><i className="bi bi-eye"></i></p>
                    <p className="no-margin">{summ_data['vision']}</p>
                </div>


            </div>

            <div className="ss-game-teams max-width">

                <div className="max-width d-flex flex-row justify-content-center align-items-center">
                    <div id="team1" className="ss-team-col  max-width">
                        {team0 && team0}
                    </div>
                    <div id="team2" className="ss-team-col  max-width">
                        {team1 && team1}
                    </div>
                </div>
            </div>


        </div>
    )
}


function Player(props) {
    return (
        <div className="my-1 mx-1 d-flex max-width">
            <img className="player-tiny" src={stub(props.patch) + "img/champion/tiles/" + props.champ + "_0.jpg"} alt="champ-icon" />
            <p className="player-tiny-text">{props.name}</p>
        </div>
    );
}


function RecentChampComponent(props) {

    let avg = AverageOutStatline(props.stats);

    return (
        <div className="d-flex my-2 w-100">
            <img className="summ-champ" src={ stub(props.patch) + "img/champion/tiles/" + props.stats.id + "_0.jpg"} alt="champion_tile"></img>
            <div className="d-flex justify-content-between align-items-center w-70 indent-s">
                <div className="flex-col">
                    <p className="no-margin"><strong>{props.stats.name}</strong></p>
                    <p className="no-margin">{Kda(props.stats).toFixed(2)} KDA</p>
                </div>
                <div className="flex-col">
                    <p className="no-margin"><strong>Stats</strong></p>
                    <p className="no-margin">{avg.kills.toFixed(1)} / <span className="text-danger">{avg.deaths.toFixed(1)}</span> / {avg.assists.toFixed(1)}</p>
                </div>
                <div className="flex-col">
                    <p className="no-margin"><strong>{Winrate(props.stats)}%</strong></p>
                    <p className="no-margin"><small>{props.stats.wins + props.stats.losses} games</small></p>
                </div>
            </div>
        </div>
    );
}




function LeftComponent(props) {
    //console.log(props);

    let masteryLoaded = false;


    let mastery = [];

    if (Array.isArray(props.mastery)) {
        mastery = props.mastery;
    }

    if (mastery.length > 0) {
        masteryLoaded = true;
    }

    let m = mastery.map((x) => {
        return <Mastery patch={props.patch} data={x} key={x.champion} />;
    });


    //console.log('oh god: ' + props.ranked)

    let r = true;
    if (props.ranked === undefined || Object.keys(props.ranked).length === 0) {
        r = false;
    }

    return (
        <div className="ss-summoner-container w-100">
            <div className="ss-summoner-grid container-xl">


                <div className="ss-name-level">
                    <div className="ss-title">{props.name}</div>
                    {false && <div className="small-margin">Level {props.level}</div>}
                </div>

                {r && <RankedQueue patch={props.patch} data={props.ranked} />}
                {!r && <RankedQueueEmpty patch={props.patch} />}

                <div className="ss-masteries container-xl">
                    <p className="ss-mastery-title ss-subtitle my-2">Masteries</p>
                    <div className="ss-mastery-items container-xl">
                        {masteryLoaded && m}
                        {!masteryLoaded && <MasteryPlaceHolder patch={props.patch} />}
                        {!masteryLoaded && <MasteryPlaceHolder patch={props.patch} />}
                        {!masteryLoaded && <MasteryPlaceHolder patch={props.patch} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Mastery(props) {
    return (
        <div className="ss-mastery inline-gap flex-row-reverse med-margin">
            <img className="summ-champ-big" src={stub(props.patch) + "img/champion/tiles/" + props.data.champion + "_0.jpg"} alt="mastery_champ_tile"></img>
            <img className="summ-champ" src={stub(props.patch) + "mastery/mastery-" + props.data.level + ".png"} alt="mastery_icon"></img>
            <div className="ss-mastery-detail flex-col align-items-end">
                <p className="no-margin lead"><strong>{props.data.name}</strong></p>
                <p className="no-margin">{numberWithCommas(props.data.points)} pts</p>
            </div>
        </div>
    )
}


function MasteryPlaceHolder(props) {
    return (
        <div className="ss-mastery inline-gap flex-row-reverse med-margin w-100">
            <img className="summ-champ-big" src={blank(props.patch)} alt="mastery_champ_tile"></img>
            <img className="summ-champ" src={blank(props.patch)} alt="mastery_icon"></img>
            <div className="ss-mastery-detail flex-col align-items-end">
                <div id="bar" className="progress small-margin">
                    <div className="progress-bar bg-secondary" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div id="bar" className="progress small-margin">
                    <div className="progress-bar bg-medium" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    )
}



function RankedQueue(props) {
    let win = props.data.winrate;
    let loss = 100 - win;
    let tier = "";
    if ((props.data.tier === "Master") || (props.data.tier === "Grandmaster") || (props.data.tier === "Challenger")) {
        tier = props.data.tier;
    } else {
        tier = props.data.tier + ' ' + props.data.rank;
    }
    return (
        <div className="ss-ranked">

            <div className="ss-rank-text">
                <p className="no-margin lead">{tier}</p>
                <p className="small-margin tiny-resize">{props.data.lp} LP - {win}% W/R - {props.data.wins}W {props.data.losses}L</p>
                <div id="bar" className="progress">
                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: win + "%" }} aria-valuenow={win} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: loss + "%" }} aria-valuenow={loss} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <img className="ss-rank-i" src={stub(props.patch) + "ranks/Emblem_" + props.data.tier + ".png"} alt="rank-preview" />
        </div>
    );
}


function RankedQueueEmpty(props) {

    return (
        <div className="ss-ranked my-3">

            <div className="ss-rank-text">
                <p className="no-margin lead">Unranked</p>
                <p className="small-margin tiny-resize">No ranked data.</p>
                <div id="bar" className="progress">
                    <div className="progress-bar bg-secondary" role="progressbar" style={{ width: "190px" }} aria-valuenow={100} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>

            <img className="ss-rank-i rounded mx-2" src={blank(props.patch)} alt="rank-preview" />
        </div>
    );
}



export default New;
