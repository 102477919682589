//import logo from './logo.svg';
import '../styles/Home.css';
import { Component } from 'react'


// make the form a reactive component to dynamically target the correct URL

function Home() {
  return (
    <div className="App">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="App-main container-sm py-4 bg-white">
            <h1>Ella: summoner focused stats</h1>

            <Input />

          </div>

        </div>

      </div>
    </div>
  );
}

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summoner: '',
      region: 'NA',
    };
    this.handleSummoner = this.handleSummoner.bind(this);
    this.handleRegion = this.handleRegion.bind(this);
  }
  handleSummoner(event) {
    this.setState({
      summoner: event.target.value
    });
  }
  handleRegion(event) {
    this.setState(
      {
        region: event.target.value
      }
    );
  }
  render() {

    return (
      <div className="container">
        <p className="fs-3">Enter your summoner name!</p>
        <div class="container">
          <div class="row px-5">
            <div class="col">
              <input
                className="form-control"
                type="text"
                value={this.state.summoner}
                onChange={this.handleSummoner}>
              </input>

            </div>
            <div class="col-4">
              <select className="form-select"
                value={this.state.region}
                onChange={this.handleRegion}>
                <option value="NA">NA</option>
                <option value="EUNE">EUNE</option>
                <option value="EUW">EUW</option>
                <option value="KR">KR</option>
                <option value="BR">BR</option>
                <option value="LAN">LAN</option>
                <option value="LAS">LAS</option>
                <option value="JP">JP</option>
                <option value="TR">TR</option>
                <option value="RU">RU</option>
                <option value="OCE">OCE</option>
              </select>
            </div>
          </div>
        </div>

        <div className="py-3">
          <a href={/summoner/ + this.state.region + "/" + this.state.summoner}><button type="submit" className="btn btn-outline-dark">View summoner!</button></a>
        </div>
      </div>

    );
  }
}

export default Home;

