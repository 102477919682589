//import logo from './logo.svg';
import './styles/Home.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


//import Wrapper from './components/Summoner';
import Home from './components/Home';
import New from './components/MobileFriendly.js'
const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/summoner/:region/:name" component={New} />
      </Switch>
    </Suspense>
  </Router>
);

export default App;

